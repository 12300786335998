import _ from 'lodash';

import * as UP from '../Upshow';
import UpshowLogger from '../Logger';
import SpotlightService from '../services/SpotlightService';
import ApplicationService from '../services/ApplicationService';
import MultipleLayoutService from '../services/MultipleLayoutService';
import BackgroundMusicService from '../services/BackgroundMusicService';
import SettingsService from '../services/SettingsService';
import MediaService from '../services/MediaService';
import FixedContentService from '../services/FixedContentService';
import ScriptService from '../services/ScriptService';
import UpNextService from '../services/UpNextService';
import StateService from '../services/StateService';
import TakeoverService from '../services/TakeoverService';
import bluebird from 'bluebird';
import InstallPromptService from '../services/InstallPromptService';
import AudioAnnouncementsService from '../services/AudioAnnouncementsService';
import { checkScriptContent } from './helpers';

const GC_INTERVAL = 1000 * 60;

function processScheduleMedia (all_schedules) {
    let schedules = [];

    if (!!all_schedules) {
        Object.keys(all_schedules).forEach((key) => {
            all_schedules[key].forEach(sch => schedules.push({
                ...sch,
                schedule_type: key,
            }));
        });
    }

    return [...schedules];
}

function processMedia (media) {
    let mediaItems = [];

    if (!!media) {
        Object.keys(media).forEach((key) => {
            media[key].forEach(sch => mediaItems.push({
                ...sch,
                type: key,
            }));
        });
    }

    return [...mediaItems];
}

function askQuota () {
    if ('serviceWorker' in navigator) {
        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({ type: 'QUOTA_ESTIMATE' });
        }
    }

    setTimeout(askQuota, 1000 * 60 * 10);
}

function startMetrics () {
    askQuota();
}

const hasCurrentStateOnTakeover = (currentState) => {
    const takeOverItems = TakeoverService.getTakeOverItems();
    return takeOverItems.some(({ schedule_type, channel_id, spotlight, applicationInstance }) => {
        if (schedule_type === 'spotlight') {
            return currentState.takeoverData.schedule_type === schedule_type && currentState.itemId === spotlight.id;
        } else if (schedule_type === 'application') {
            return currentState.takeoverData.schedule_type === schedule_type && currentState.itemId === applicationInstance.id;
        } else {
            return schedule_type === 'trivia' || (currentState.takeoverData.schedule_type === schedule_type && currentState.itemId === channel_id);
        }
    });
};

const hasCurrentStateScheduled = (activeScriptState, currentState) => {
    const currentStateName = currentState.name;
    return activeScriptState.some(scriptStep => {
        const { metadata } = scriptStep;
        if (metadata && metadata.id && !!metadata.id.length) { //Granularity
            return metadata.id.includes(currentState.itemId);
        } else {
            //Schedule
            if (currentStateName === 'spotlight') {
                const spotlightSchedule = SpotlightService.getSpotlightsScheduled();
                return spotlightSchedule.some(schedule => currentState.itemId === schedule.spt_id);
            }

            const media_states = ['upshownow', 'jukin', 'plutotv', 'trivia'];

            if (media_states.includes(currentStateName)) {
                const activeScheduleMedia = MediaService.getAllActive(media_states);
                return activeScheduleMedia.some(({ channel_id, schedule_type }) => channel_id === currentState.itemId && schedule_type === currentStateName);
            }

            if (currentStateName === 'application') {
                const activeScheduleMediaApplication = MediaService.getAllActive(['application']);
                return activeScheduleMediaApplication.some(schedule => schedule.applicationInstance.id === currentState.itemId);
            }

            return true;
        }
    });
};

const isCurrentStateStillValid = () => {
    const currentState = StateService.currentStateDef;
    const currentStateName = currentState.name;

    if (currentStateName === 'loading' || currentStateName === 'live_event') return true;

    if (currentState.source === 'takeover') return hasCurrentStateOnTakeover(currentState);

    const inEmptyState = currentStateName === 'empty_state';

    const noContent = !StateService.scriptHasContent;

    if (!inEmptyState && noContent) return false; // We are in other state and no content
    if (inEmptyState) return noContent; // We are in empty state check if we have content

    const activeScript = ScriptService.getActiveScheduledScript();

    const activeScriptState = activeScript.filter(scriptStep => scriptStep.state === currentStateName);

    if (activeScriptState.length === 0) {
        return false;
    }

    // The following states do not require scheduling or granularity
    if (['iframe', 'socialzoom', 'socialgrid3', 'watchtowin'].includes(currentStateName)) {
        return true;
    }

    if (SettingsService.hasFeature('dr_time_based')) {
        //todo evaluate if the current state is still valid
        return true;
    }

    return hasCurrentStateScheduled(activeScriptState, currentState);
};

let retry_count = 0;

const syncState = async (lastTimeStamp) => {
    if (window.inactive) return;

    let delay = window.demo ? 60_000 : 10_000;
    let timestamp = 0;

    try {
        const result = await UP.getState(); // Query the backend
    
        if (result) { // We have data
            timestamp = result.timestamp;
            retry_count = 0;
            if (lastTimeStamp !== timestamp) { // We have NEW data
                UP.clearSpotlightCache();

                UpshowLogger.debug('syncState', 'Sync new state, timestamp: ' + timestamp);

                const {
                    settings, campaigns, application_schedules, media_channel_schedules,
                    plutotv_schedules, trivia_schedules, upshownow_schedules, audio_schedules,
                    scheduled_scripts, spotlight_schedules, content, default_org_script, active_smart_script, AudioAnnouncements, spotlights, upshownow, media_channel,
                    plutotv, applicationInstances, trivia, activeLiveEvent, watch_to_win_settings
                } = result;

                SettingsService.loadSettings(settings);

                StateService.setLiveEventStatus(activeLiveEvent, timestamp);

                SpotlightService.loadSpotlights(
                    spotlight_schedules,
                    _.uniqBy(_.flatten([
                        ...spotlight_schedules.map(ss => ss.spotlight),
                        ...scheduled_scripts.reduce((carry, ss) => {
                            carry = carry.concat(_.flatten(ss.script?.Sequence.filter(s => s.state === 'spotlight').map(s => s.ScriptStates)) ?? []);
                            return carry;
                        }, []),
                        ...default_org_script.Sequence.filter(s => s.state === 'spotlight').map(s => s.ScriptStates),
                        ...spotlights
                    ]).filter(s => !!s), 'id'),
                );

                ApplicationService.loadApplications(
                    _.uniqBy(_.flatten([
                        ...application_schedules.map(ss => ss.applicationInstance),
                        ...scheduled_scripts.reduce((carry, ss) => {
                            carry = carry.concat(_.flatten(ss.script?.Sequence.filter(s => s.state === 'application').map(s => s.ScriptStates)) ?? []);
                            return carry;
                        }, []),
                        ...default_org_script.Sequence.filter(s => s.state === 'application').map(s => s.ScriptStates)
                    ]).filter(app => !!app), 'id')
                );

                const scheduledMedia = processScheduleMedia({
                    application: application_schedules,
                    jukin: media_channel_schedules,
                    plutotv: plutotv_schedules,
                    trivia: trivia_schedules,
                    upshownow: upshownow_schedules
                });

                const media = {
                    upshownow,
                    jukin: media_channel,
                    plutotv,
                    application: applicationInstances,
                    trivia
                };

                const displayRulesMedia = processMedia(media);
                StateService.hasWatchToWinSettings = watch_to_win_settings.length > 0;
                MultipleLayoutService.updateMultipleLayoutContent();
                AudioAnnouncementsService.announcements = AudioAnnouncements.map(a => a.audio);
                BackgroundMusicService.schedules = audio_schedules;
                MediaService.loadMedia([...displayRulesMedia, ...scheduledMedia]);
                FixedContentService.renderFixedContent();
                BackgroundMusicService.play();
                AudioAnnouncementsService.start();
                MultipleLayoutService.updateSettings();
                UpshowLogger.debug('StateService', 'Updated settings');

                ScriptService.loadSchedulesScripts({ 
                    schedules_scripts: scheduled_scripts, 
                    org_default_script: default_org_script.sequence,
                    active_smart_script: active_smart_script.sequence 
                });
                FixedContentService.loadCampaigns(campaigns);
                UpNextService.setContent(content);
                StateService.updateSettings();
                StateService.scriptHasContent = checkScriptContent(spotlights, upshownow, plutotv, media_channel, applicationInstances, trivia, StateService.hasWatchToWinSettings);

                if (!isCurrentStateStillValid()) {
                    UpshowLogger.warn('StateService', 'Forced Advance State');
                    StateService.advanceState();
                }
            }
        }
    } catch (error) {
        UpshowLogger.error(['index', 'syncState'], 'Error synchronizing new state ' + error);
        const RETRY_DELAYS = [10_000, 30_000, 60_000];
        delay = RETRY_DELAYS[retry_count];
        if (retry_count < (RETRY_DELAYS.length - 1)) retry_count++;
    }

    setTimeout(() => syncState(timestamp), delay);
};

function setInitialSettings () {
    if (SettingsService.hasTrueUiSetting('show_overscan')) {
        window.document.getElementById('overscan-red-zone').style.display = 'block';
    }

    if (SettingsService.hasUiSetting('debug_level')) {
        UpshowLogger.debug_level = SettingsService.getUiSetting('debug_level');
    }

    if (SettingsService.hasTrueUiSetting('performance_memory')) {
        window.setInterval(function () {
            UpshowLogger.debug('performance.memory', '', window.performance.memory);
        }, 5000);
    }

    if (SettingsService.hasUiSetting('forced_iso_date')) {
        UP.setCurrentISODate(SettingsService.getUiSetting('forced_iso_date'));
    }

    //set theme
    if (SettingsService.getUiSetting('theme') !== undefined) {
        document.documentElement.className += ' theme-' + SettingsService.getUiSetting('theme');
    }
}

const initializeApplication = async () => {
    UpshowLogger.log('index', 'Initialized application ');
    UP.attachMessageListener();

    startMetrics(); // Start metrics polling
    await syncState(0);
    setInitialSettings();

    const script = ScriptService.getScript();

    StateService.updateSettings();
    StateService.setScript(script);
    ScriptService.startScriptService();

    setInterval(() => { StateService.gc(); }, GC_INTERVAL);

    StateService.showLoadingState();

    // If we are using skykit then the loading screen will not be rendered and the next state should be shown as quickly as possible
    const wait_to_start = ['AndroidSkykit','Skykit'].includes(UP.getDeviceInfo().model) ? 0 : 4000;
    await bluebird.delay(wait_to_start);

    FixedContentService.renderFixedContent();
    MultipleLayoutService.init();
    InstallPromptService.init();
    TakeoverService.loadTakeoverService();
    StateService.advanceState();
};

export default initializeApplication;