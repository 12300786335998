import React from 'react';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';

import { teslaCacheX } from '../../Upshow';
import ScreenService from '../../services/ScreenService';
import Jukin from './Jukin';
import MediaPlayingState from '../MediaPlayingState';
import StateFactory from '../StateFactory';
import JukinService from '../../services/JukinService';
import { StatePreconditionError } from '../Errors';

import UpshowLogger from '../../Logger';
import { isVideoFile } from './utils';
import SettingsService from '../../services/SettingsService';
import { UpshowContextProvider } from '../../UpshowContext';

class JukinState extends MediaPlayingState {
    constructor (node, state) {
        super(
            node,
            state,
            null,
            false,
            true,
            { track: false }
        );

        this.state.channel = {
            id: _get(this.state, 'state.itemId', null)
        };

        if (!this.state.channel.id) {
            // Get Schedule when the Jukin is in Script
            const schedule = JukinService.getNextScheduleItem();
            if (!schedule) {
                throw new StatePreconditionError('Error loading JukinState without a valid schedule');
            }
            this.state.channel = {
                id: schedule.channel_id
            };
        }

        this.metricData = { ...this.metricData, id: parseInt(this.state.channel.id) };
    }

    get name () {
        return 'jukin';
    }

    get logImpressions () {
        return false;
    }

    async preload () {
        const { channel } = this.state;
        if (!channel || !channel.id) {
            throw new StatePreconditionError('Error on preload JukinState -  no valid channel');
        }
        UpshowLogger.log(['jukin'], `jukin preloading channel: ${channel.id} `);
        try {
            const response = await JukinService.getChannelData(channel);
            const { content, metadata } = response;

            this.state.jukinMedia = content;

            this.state.preRollAnimation = metadata.pre_roll_url;
            this.state.preRollImage = metadata.pre_roll_bg_image;
            this.state.postRollAnimation = metadata.post_roll_url;
            this.state.watermarkUrl = metadata.watermark_url;
            this.state.muted = metadata.is_muted || SettingsService.hasTrueUiSetting('muted');

            if (!this.state.muted) {
                this.state.soundState.shouldSound = true;
            }

        } catch (err) {
            throw new StatePreconditionError(`Error on preload JukinState channel`, `${channel.id} ${err.message}`);
        }

        if (!!this.state.preRollAnimation ^ !!this.state.preRollImage) {
            throw new Error(`missing animation or background image preroll part`);
        }
        if (!!this.state.preRollImage) {
            this.state.preRollImage = teslaCacheX(this.state.preRollImage);
        }
        if (isVideoFile(this.state.preRollAnimation)) {
            this.state.preRollAnimation = teslaCacheX(this.state.preRollAnimation);
        }

        if (isVideoFile(this.state.postRollAnimation)) {
            this.state.postRollAnimation = teslaCacheX(this.state.postRollAnimation);
        }

        this._render();
        return this.state.readyPromise;
    }

    _render () {
        const media = this.state.jukinMedia;

        const useSequentialRolls =
            (!this.state.preRollAnimation || isVideoFile(this.state.preRollAnimation)) &&
            (!this.state.postRollAnimation || isVideoFile(this.state.postRollAnimation));

        ReactDOM.render(<UpshowContextProvider>
            <Jukin
                skipPlates={useSequentialRolls}
                media={useSequentialRolls
                    ? [{ url: this.state.preRollAnimation }, ...media, { url: this.state.postRollAnimation }].filter(({ url }) => !!url)
                    : media}
                playing={this.state.playing}
                onPlay={this.raisePlaying}
                onReady={this.raiseReady}
                onEnded={this.raiseDone}
                onError={this.raiseError}
                onProgress={this.onProgress}
                onDuration={this.onDuration}
                preRollAnimation={this.state.preRollAnimation}
                postRollAnimation={this.state.postRollAnimation}
                preRollImage={this.state.preRollImage}
                watermarkUrl={this.state.watermarkUrl}
                muted={this.state.muted}
            />
        </UpshowContextProvider>, this.node);
    }

    static appliesTo () {
        return !ScreenService.isVertical;
    }
}

StateFactory.register('jukin', JukinState, 10);

export default JukinState;
